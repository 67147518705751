import React, { useRef, useEffect } from 'react';
import { Box, IconButton, Typography, Button, Grid, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import '../../../../css/popup.css';
import { PokemonCard } from '../../../../api/pokemonSetApi';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { usePokemonSetFunctions } from '../../../../hooks/usePokemonSetFunctions';
import { useAuth } from '../../../../context/AuthContext';

interface Props {
    cardInfo: PokemonCard;
    onClose: () => void;
    onSubmitSuccess?: () => void;
    onCompleteSuccess?: () => void;
}

const UploadBox = styled(Box)(({ theme }) => ({
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    textAlign: 'center',
    cursor: 'pointer',
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const CardImageTemplate: React.FC<Props> = ({
    cardInfo,
    onClose,
    onSubmitSuccess,
    onCompleteSuccess,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { uploadCardImage, deleteCardImage } = usePokemonSetFunctions();
    const { authParams } = useAuth();

    const getEditionFolderName = (editionName: string): string => {
        switch (editionName) {
            case 'First Edition':
                return 'First Edition';
            case 'Shadowless':
                return 'Shadowless';
            case 'Unlimited':
                return 'Unlimited';
            case '4th Print':
                return '4th Print';
            default:
                return '';
        }
    };

    const handleFileSelect = async (
        event: React.ChangeEvent<HTMLInputElement>,
        type: 'standard' | 'high'
    ) => {
        const file = event.target.files?.[0];
        if (!file || !authParams) return;

        // Generate the filename based on card properties
        const baseFilename = cardInfo.isReverseHolo ? `${cardInfo.number}_R` : `${cardInfo.number}`;

        const filename = type === 'high' ? `${baseFilename}-2x.jpg` : `${baseFilename}.jpg`;

        // Generate the full path including edition folder
        const editionFolder = getEditionFolderName(cardInfo.editionName);
        const fullPath = editionFolder
            ? `${cardInfo.image_folder}/${editionFolder}`
            : cardInfo.image_folder;

        const result = await uploadCardImage(
            file,
            cardInfo.id,
            fullPath,
            filename,
            authParams.userName,
            authParams.adminKey
        );

        if (result.success && result.imageUrl) {
            // Update the card info with the new image URL
            if (type === 'high') {
                cardInfo.imageLocation2x = result.imageUrl;
            } else {
                cardInfo.imagelocation = result.imageUrl;
            }

            if (onSubmitSuccess) {
                onSubmitSuccess();
            }
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>, type: 'standard' | 'high') => {
        event.preventDefault();
        event.stopPropagation();

        const files = event.dataTransfer.files;
        if (files && files[0]) {
            handleFileSelect({ target: { files } } as any, type);
        }
    };

    const handleDeleteImage = async (type: 'standard' | 'high') => {
        if (!authParams) return;

        const result = await deleteCardImage(
            cardInfo.id,
            type,
            authParams.userName,
            authParams.adminKey
        );

        if (result.success) {
            // Update the card info
            if (type === 'high') {
                cardInfo.imageLocation2x = null;
            } else {
                cardInfo.imagelocation = null;
            }

            if (onSubmitSuccess) {
                onSubmitSuccess();
            }
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;

            // Check if click is on Autocomplete or its dropdown
            const isAutocompleteClick = target.closest('.MuiAutocomplete-root') !== null;
            const isAutocompletePopup = target.closest('.MuiAutocomplete-popper') !== null;
            const isCheckboxClick = target.closest('.MuiCheckbox-root') !== null;

            if (isAutocompleteClick || isAutocompletePopup || isCheckboxClick) {
                return;
            }

            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    useEffect(() => {}, []);

    return (
        <Box className="popup-overlay">
            <Box ref={containerRef} className="popup-container">
                <Box className="popup-header">
                    <h2 className="popup-title">{cardInfo.name}</h2>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: '10px',
                            top: '10px',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box className="popup-content">
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {/* Card Info Paper */}
                        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 2,
                                    '& > div': {
                                        flex: '1 1 200px',
                                        minWidth: '200px',
                                    },
                                }}
                            >
                                <div>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Series Name
                                    </Typography>
                                    <Typography variant="body1">{cardInfo.seriesName}</Typography>
                                </div>
                                <div>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Set Name
                                    </Typography>
                                    <Typography variant="body1">{cardInfo.setName}</Typography>
                                </div>
                                <div>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Card Name
                                    </Typography>
                                    <Typography variant="body1">{cardInfo.name}</Typography>
                                </div>
                                <div>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Card Number
                                    </Typography>
                                    <Typography variant="body1">{cardInfo.number}</Typography>
                                </div>
                                <div>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Card Type
                                    </Typography>
                                    <Typography variant="body1">
                                        {cardInfo.isReverseHolo ? 'Reverse Holo' : 'Standard'}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Edition
                                    </Typography>
                                    <Typography variant="body1">{cardInfo.editionName}</Typography>
                                </div>
                            </Box>
                        </Paper>

                        <Box sx={{ mt: 2 }}>
                            <Typography variant="h6" gutterBottom>
                                Card Images
                            </Typography>
                            <Grid container spacing={2}>
                                {/* Standard Resolution Image */}
                                <Grid item xs={12} md={6}>
                                    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                                        <Typography
                                            variant="subtitle2"
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            Standard Resolution
                                        </Typography>
                                        {cardInfo.imagelocation ? (
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    '&:hover .overlay': { opacity: 1 },
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <LazyLoadImage
                                                    src={cardInfo.imagelocation}
                                                    alt={`${cardInfo.name} - Standard`}
                                                    effect="blur"
                                                    style={{
                                                        width: '100%',
                                                        height: 'auto',
                                                        maxWidth: '300px',
                                                    }}
                                                />
                                                <Box
                                                    className="overlay"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        opacity: 0,
                                                        transition: 'opacity 0.3s',
                                                        pointerEvents: 'none',
                                                        '& button': {
                                                            pointerEvents: 'auto'
                                                        }
                                                    }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() => handleDeleteImage('standard')}
                                                    >
                                                        Delete Image
                                                    </Button>
                                                </Box>
                                            </Box>
                                        ) : (
                                            <label htmlFor="standard-image-upload">
                                                <UploadBox
                                                    component="div"
                                                    onDragOver={handleDragOver}
                                                    onDrop={(e) => handleDrop(e, 'standard')}
                                                >
                                                    <input
                                                        type="file"
                                                        id="standard-image-upload"
                                                        hidden
                                                        accept="image/*"
                                                        onChange={(e) =>
                                                            handleFileSelect(e, 'standard')
                                                        }
                                                    />
                                                    <CloudUploadIcon sx={{ fontSize: 40, mb: 1 }} />
                                                    <Typography>
                                                        Drop standard resolution image here or click
                                                        to upload
                                                    </Typography>
                                                </UploadBox>
                                            </label>
                                        )}
                                    </Paper>
                                </Grid>

                                {/* High Resolution Image */}
                                <Grid item xs={12} md={6}>
                                    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                                        <Typography
                                            variant="subtitle2"
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            High Resolution (2x)
                                        </Typography>
                                        {cardInfo.imageLocation2x ? (
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    '&:hover .overlay': { opacity: 1 },
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <LazyLoadImage
                                                    src={cardInfo.imageLocation2x}
                                                    alt={`${cardInfo.name} - High Resolution`}
                                                    effect="blur"
                                                    style={{
                                                        width: '100%',
                                                        height: 'auto',
                                                        maxWidth: '300px',
                                                    }}
                                                />
                                                <Box
                                                    className="overlay"
                                                    sx={
                                                        {
                                                            /* ... existing overlay styles ... */
                                                        }
                                                    }
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() => handleDeleteImage('high')}
                                                    >
                                                        Delete Image
                                                    </Button>
                                                </Box>
                                            </Box>
                                        ) : (
                                            <label htmlFor="high-res-image-upload">
                                                <UploadBox
                                                    component="div"
                                                    onDragOver={handleDragOver}
                                                    onDrop={(e) => handleDrop(e, 'high')}
                                                >
                                                    <input
                                                        type="file"
                                                        id="high-res-image-upload"
                                                        hidden
                                                        accept="image/*"
                                                        onChange={(e) =>
                                                            handleFileSelect(e, 'high')
                                                        }
                                                    />
                                                    <CloudUploadIcon sx={{ fontSize: 40, mb: 1 }} />
                                                    <Typography>
                                                        Drop high resolution image here or click to
                                                        upload
                                                    </Typography>
                                                </UploadBox>
                                            </label>
                                        )}
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default CardImageTemplate;
